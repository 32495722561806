// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex items-center mt-8 justify-center container mx-auto min-h-96 bg-white bg-opacity-80 dark:bg-gray-900 dark:bg-opacity-80 p-8 rounded-lg shadow-lg">
      <div className="text-center py-12 lg:py-64">
        <h1 className="text-8xl font-bold text-gray-800 dark:text-gray-300 mb-4">404</h1>
        <p className="text-2xl text-gray-700 dark:text-gray-400 mb-8">Page Not Found</p>
        <Link to="/" className="px-4 py-2 bg-gray-950 hover:bg-gray-700 text-white font-bold rounded-full">
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
