import React from 'react';
import Select from 'react-select';

const SelectTypeField = ({ type, handleTypeChange, theme }) => {
  const options = [
    { value: 'link', label: 'Link' },
    { value: 'text', label: 'Text' },
    { value: 'email', label: 'E-mail' },
    { value: 'location', label: 'Location' },
    { value: 'phone', label: 'Phone' },
    { value: 'sms', label: 'SMS' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'wifi', label: 'WI-FI' },
    { value: 'vcard', label: 'V-card' },
    { value: 'event', label: 'Event' },
    { value: 'paypal', label: 'PayPal' },
    { value: 'bitcoin', label: 'BitCoin' },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: theme === 'dark' ? '#2d3748' : '#fff',
      borderColor: state.isFocused ? '#63b3ed' : theme === 'dark' ? '#2d3748' : '#d1d5db',
      color: theme === 'dark' ? '#fff' : '#000',
      '&:hover': {
        borderColor: '#63b3ed',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === 'dark' ? '#2d3748' : '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#63b3ed' : theme === 'dark' ? '#2d3748' : '#fff',
      color: state.isFocused ? (theme === 'dark' ? '#1a202c' : '#fff') : (theme === 'dark' ? '#fff' : '#000'),
      '&:hover': {
        backgroundColor: '#63b3ed',
        color: theme === 'dark' ? '#1a202c' : '#fff',
      },
    }),
  };

  return (
    <div className="space-y-2">
      <label htmlFor="type" className="block text-gray-700 font-medium dark:text-gray-300">Type:</label>
      <Select
        id="type"
        value={options.find(option => option.value === type)}
        onChange={(selectedOption) => handleTypeChange({ target: { value: selectedOption.value } })}
        options={options}
        styles={customStyles}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default SelectTypeField;
