import React from 'react';
import { PiFileJpg, PiFilePng, PiFileSvg } from "react-icons/pi";

const DownloadLinks = ({ qrCodeUrl }) => (
  <div className="grid grid-cols-3 gap-4 mt-8">
    <div className="border border-gray-400 p-2 rounded-lg shadow-md bg-gray-100 dark:bg-gray-800">
      <div className="text-center mb-2">
        <a
          id="download-link-png"
          href={qrCodeUrl}
          download="qrcode.png"
          className="inline-flex items-center justify-center w-12 h-12 bg-gray-800 dark:bg-gray-700 text-white rounded-lg hover:bg-gray-600 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          <PiFilePng size={36} />
        </a>
      </div>
      <div className="text-xs text-center text-gray-700 dark:text-gray-300">Download PNG</div>
    </div>

    <div className="border border-gray-400 p-2 rounded-lg shadow-md bg-gray-100 dark:bg-gray-800">
      <div className="text-center mb-2">
        <a
          id="download-link-svg"
          href={qrCodeUrl.replace('image/png', 'image/svg+xml')}
          download="qrcode.svg"
          className="inline-flex items-center justify-center w-12 h-12 bg-gray-800 dark:bg-gray-700 text-white rounded-lg hover:bg-gray-600 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          <PiFileSvg size={36} />
        </a>
      </div>
      <div className="text-xs text-center text-gray-700 dark:text-gray-300">Download SVG</div>
    </div>

    <div className="border border-gray-400 p-2 rounded-lg shadow-md bg-gray-100 dark:bg-gray-800">
      <div className="text-center mb-2">
        <a
          id="download-link-jpg"
          href={qrCodeUrl.replace('image/png', 'image/jpeg')}
          download="qrcode.jpg"
          className="inline-flex items-center justify-center w-12 h-12 bg-gray-800 dark:bg-gray-700 text-white rounded-lg hover:bg-gray-600 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          <PiFileJpg size={36} />
        </a>
      </div>
      <div className="text-xs text-center text-gray-700 dark:text-gray-300">Download JPG</div>
    </div>
  </div>

);

export default DownloadLinks;
