// src/components/QRCodeForm.js
// eslint-disable-next-line 
import React, { useState, useEffect, useContext } from 'react';
import QRDescriptions from './QRDescriptions';
import SelectTypeField from './SelectTypeField';
import LinkField from './fields/LinkField';
import PhoneFields from './fields/PhoneFields';
import TextFields from './fields/TextFields';
import AdditionalFields from './AdditionalFields';
import DownloadLinks from './DownloadLinks';
import QRCode from 'qrcode';
import ThemeContext from '../ThemeContext';

const QRCodeForm = () => {
  const { theme } = useContext(ThemeContext);
  const [type, setType] = useState('link');
  const [data, setData] = useState('');
  const [additionalFields, setAdditionalFields] = useState({});
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!data && !['whatsapp', 'wifi', 'vcard', 'email', 'phone', 'sms', 'location', 'event', 'paypal', 'bitcoin'].includes(type)) {
      setError('Data is required');
      setLoading(false);
      return;
    }

    let qrData;

    switch (type) {
      case 'link':
        qrData = data;
        break;
      case 'text':
        qrData = data;
        break;
      case 'email':
        qrData = `mailto:${additionalFields.email}?subject=${encodeURIComponent(additionalFields.subject)}&body=${encodeURIComponent(additionalFields.body)}`;
        break;
      case 'whatsapp':
        qrData = `https://wa.me/${additionalFields.phone}?text=${encodeURIComponent(additionalFields.message)}`;
        break;
      case 'wifi':
        qrData = `WIFI:T:${additionalFields.encryption};S:${additionalFields.ssid};P:${additionalFields.password};;`;
        break;
      case 'vcard':
        qrData = `BEGIN:VCARD\nVERSION:3.0\nN:${additionalFields.name}\nTEL:${additionalFields.phone}\nEMAIL:${additionalFields.email}\nADR:${additionalFields.address}\nORG:${additionalFields.company}\nTITLE:${additionalFields.title}\nEND:VCARD`;
        break;
      case 'phone':
        qrData = `tel:${data}`;
        break;
      case 'sms':
        qrData = `SMSTO:${additionalFields.phone}:${additionalFields.message}`;
        break;
      case 'location':
        qrData = `geo:${additionalFields.latitude},${additionalFields.longitude}`;
        break;
      case 'event':
        const dtStart = `${additionalFields.date.replace(/-/g, '')}T${additionalFields.time.replace(/:/g, '')}00Z`;
        const dtEnd = additionalFields.endDate && additionalFields.endTime ? `${additionalFields.endDate.replace(/-/g, '')}T${additionalFields.endTime.replace(/:/g, '')}00Z` : '';
        qrData = `BEGIN:VEVENT\nSUMMARY:${additionalFields.eventName}\nDTSTART;TZID=${additionalFields.timeZone}:${dtStart}\n${dtEnd ? `DTEND;TZID=${additionalFields.timeZone}:${dtEnd}\n` : ''}LOCATION:${additionalFields.location}\n${additionalFields.description ? `DESCRIPTION:${additionalFields.description}\n` : ''}END:VEVENT`;
        break;
      case 'paypal':
        qrData = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=${additionalFields.paypalEmail}&item_name=${additionalFields.itemName}&amount=${additionalFields.amount}&currency_code=${additionalFields.currencyCode}`;
        break;
      case 'bitcoin':
        qrData = `bitcoin:${additionalFields.walletAddress}?amount=${additionalFields.amount}`;
        break;
      default:
        qrData = data;
        break;
    }

    try {
      const qrCode = await QRCode.toDataURL(qrData, { width: 600, margin: 2, errorCorrectionLevel: 'H' });
      setQrCodeUrl(qrCode);
      setError('QR code generated successfully!');
    } catch (err) {
      setError(`Failed to generate QR code: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setAdditionalFields({});
    setData('');
  };

  const currentDescription = QRDescriptions[type];

  return (
    <div className={`bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto mt-8 ${theme}`}>
        <div className="text-gray-700 dark:text-gray-300 text-start mb-4 text-sm flex flex-col space-y-2 p-3 border border-gray-100 dark:border-gray-500 rounded-lg shadow dark:shadow-slate-50">
          <h3 className='text-xl font-semibold'>Welcome</h3>
          <p>Select a type from the dropdown to create different kinds of QR codes.</p>
        </div>
      <div className='flex flex-col space-y-3 my-4'>
        <h2 className="text-xl font-semibold mb-1 text-center text-gray-700 dark:text-gray-300">{currentDescription.title}</h2>
        <p className="text-start mb-4 text-gray-600 dark:text-gray-400">{currentDescription.description}</p>
      </div>
      {error && <p className={`text-center mb-4 ${error.includes('Failed') ? 'text-red-500' : 'text-green-500 border border-green-500 rounded-lg p-2'}`}>{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <SelectTypeField type={type} handleTypeChange={handleTypeChange} theme={theme} />
        {type === 'link' && <LinkField data={data} setData={setData} theme={theme} />}
        {type === 'text' && <TextFields data={data} setData={setData} theme={theme} />}
        {type === 'phone' && <PhoneFields data={data} setData={setData} theme={theme} />}
        <AdditionalFields type={type} additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />
        <div className="text-center">
          <button
            type="submit"
            className="px-6 py-3 bg-gray-800 dark:bg-gray-700 text-white font-medium rounded-md shadow hover:bg-gray-950 dark:hover:bg-gray-950 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            {loading ? 'Generating...' : 'Generate QR Code'}
          </button>
        </div>
      </form>

      {qrCodeUrl && (
        <div id="qr-result" className="mt-8 text-center">
          <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">Generated QR Code:</h2>
          <img id="qr-code-image" src={qrCodeUrl} alt="QR Code" className="mx-auto mt-4 w-80 h-80 border border-gray-200 dark:border-gray-700 rounded-md shadow-sm" />
          <DownloadLinks qrCodeUrl={qrCodeUrl} />
        </div>
      )}
    </div>
  );
};

export default QRCodeForm;
