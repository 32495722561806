const LocationFields = ({ additionalFields, setAdditionalFields }) => (
    <>
      <div>
        <label htmlFor="latitude" className="block text-gray-700 font-medium dark:text-gray-300">Latitude:</label>
        <input
          type="text"
          id="latitude"
          name="latitude"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.latitude || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, latitude: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="longitude" className="block text-gray-700 font-medium dark:text-gray-300">Longitude:</label>
        <input
          type="text"
          id="longitude"
          name="longitude"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.longitude || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, longitude: e.target.value })}
          required
        />
      </div>
    </>
  );
  
  export default LocationFields;
  