import React from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const SMSFields = ({ additionalFields, setAdditionalFields }) => (
  <>
    <div>
      <label htmlFor="phone" className="block text-gray-700 font-medium dark:text-gray-300">Phone Number:</label>
      <PhoneInput
        country={'us'}
        value={additionalFields.phone || ''}
        onChange={(phone) => setAdditionalFields({ ...additionalFields, phone })}
        containerClass="w-full mt-1"
        inputClass="w-full border-gray-300 dark:border-none dark:bg-gray-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 dark:text-gray-600"
        buttonClass="bg-white border-gray-300 dark:bg-gray-700 dark:border-none"
        dropdownClass="dark:bg-gray-700 dark:text-gray-600"
        countryCodeEditable={false}
        enableSearch={true}
      />
    </div>
    <div>
      <label htmlFor="message" className="block text-gray-700 font-medium dark:text-gray-300">Message:</label>
      <textarea
        id="message"
        name="message"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.message || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, message: e.target.value })}
      />
    </div>
  </>
);

export default SMSFields;
