const QRDescriptions = {
    link: {
      title: "Link QR Code",
      description: "This QR code will redirect to the provided URL when scanned.",
    },
    text: {
      title: "Text QR Code",
      description: "This QR code will display the provided text message when scanned.",
    },
    email: {
      title: "Email QR Code",
      description: "This QR code will open the email client with pre-filled recipient, subject, and message.",
    },
    whatsapp: {
      title: "WhatsApp QR Code",
      description: "This QR code will open a WhatsApp chat with the provided phone number and message.",
    },
    wifi: {
      title: "Wi-Fi QR Code",
      description: "This QR code will connect to the provided Wi-Fi network when scanned.",
    },
    vcard: {
      title: "vCard QR Code",
      description: "This QR code will save the provided contact information to the phone's address book.",
    },
    phone: {
      title: "Phone QR Code",
      description: "This QR code will dial the provided phone number when scanned.",
    },
    sms: {
      title: "SMS QR Code",
      description: "This QR code will open the SMS app with the provided phone number and message.",
    },
    location: {
      title: "Location QR Code",
      description: "This QR code will open the provided latitude and longitude coordinates in a map app.",
    },
    event: {
      title: "Event QR Code",
      description: "This QR code will save the provided event details to the calendar.",
    },
    paypal: {
      title: "PayPal QR Code",
      description: "This QR code will open PayPal with the provided payment details.",
    },
    bitcoin: {
      title: "Bitcoin QR Code",
      description: "This QR code will open the Bitcoin wallet with the provided address and amount.",
    },
  };
  
  export default QRDescriptions;
  