const WifiFields = ({ additionalFields, setAdditionalFields }) => (
    <>
      <div>
        <label htmlFor="ssid" className="block text-gray-700 font-medium dark:text-gray-300">SSID:</label>
        <input
          type="text"
          id="ssid"
          name="ssid"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.ssid || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, ssid: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="password" className="block text-gray-700 font-medium dark:text-gray-300">Password:</label>
        <input
          type="text"
          id="password"
          name="password"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.password || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, password: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="encryption" className="block text-gray-700 font-medium dark:text-gray-300">Encryption:</label>
        <select
          id="encryption"
          name="encryption"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.encryption || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, encryption: e.target.value })}
          required
        >
          <option value="WPA">WPA</option>
          <option value="WEP">WEP</option>
          <option value="">None</option>
        </select>
      </div>
    </>
  );
  
  export default WifiFields;
  