const EmailFields = ({ additionalFields, setAdditionalFields }) => (
    <>
      <div>
        <label htmlFor="email" className="block text-gray-700 font-medium dark:text-gray-300">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"          value={additionalFields.email || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, email: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="subject" className="block text-gray-700 font-medium dark:text-gray-300">Subject:</label>
        <input
          type="text"
          id="subject"
          name="subject"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"        value={additionalFields.subject || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, subject: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="body" className="block text-gray-700 font-medium dark:text-gray-300">Body:</label>
        <textarea
          id="body"
          name="body"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.body || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, body: e.target.value })}
        />
      </div>
    </>
  );
  
  export default EmailFields;
  