import React from 'react';

const LinkField = ({ data, setData }) => (
  <div id="data-field">
    <label htmlFor="data" className="block text-gray-700 dark:text-gray-300 font-medium">Link:</label>
    <input
      type="url"
      id="link"
      name="link"
      placeholder="Enter your URL here"
      className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
      value={data}
      onChange={(e) => setData(e.target.value)}
      required
    />
    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">Please enter the required link.</p>
  </div>
);

export default LinkField;
