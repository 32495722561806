// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QRCodeForm from './components/QRCodeForm';
import About from './pages/About';
import NotFound from './pages/NotFound'; // Importar la nueva página
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeProvider } from './ThemeContext';

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen relative">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: "url('/bg.jpg')" }}
          >
            <div className="absolute inset-0 bg-white opacity-60 dark:bg-gray-900 dark:opacity-60"></div>
          </div>
          <div className="relative z-10">
            <Header />
            <Routes>
              <Route path="/" element={<QRCodeForm />} />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<NotFound />} /> {/* Ruta para la página 404 */}
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
