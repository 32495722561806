import React from 'react';

const TextFields = ({ data, setData }) => (
  <div id="data-field">
    <label htmlFor="data" className="block text-gray-700 dark:text-gray-300 font-medium">Text:</label>
    <textarea
      id="data"
      name="data"
      placeholder="Enter your text here"
      className="block w-full mt-1 border text-gray-700 dark:text-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:ring-gray-200 focus:border-gray-200 p-2 dark:bg-gray-700"
      value={data}
      onChange={(e) => setData(e.target.value)}
      required
    />
    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">Please enter the text you want to encode in the QR code.</p>
  </div>
);

export default TextFields;
