// src/pages/About.js
import React from 'react';

const About = () => {
  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg md:max-w-7xl mx-auto mt-4">
      <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800 dark:text-gray-300 mb-6">About QR Code Generator</h1>
      <p className="text-gray-700 dark:text-gray-300 text-md leading-snug">
        Welcome to the QR Code Generator WebApp! Our application is designed to provide you with a seamless and efficient way to create various types of QR codes for different purposes. Whether you need to generate a QR code for a link, text, email, location, phone number, SMS, WhatsApp message, Wi-Fi, vCard, event, PayPal, or Bitcoin, our app has got you covered.
      </p>
      <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-gray-300 mt-10 mb-4">Key Features</h2>
      <div className="space-y-3 mt-4 text-gray-700 pl-4 dark:text-gray-300 text-md leading-snug">
        <div>
          <span>Easy-to-use interface for quick QR code generation.</span>
        </div>
        <div>
          <span>Supports a wide range of QR code types, including links, texts, emails, and more.</span>
        </div>
        <div>
          <span>Customizable options for each QR code type to fit your specific needs.</span>
        </div>
        <div>
          <span>Dark and light theme support for better user experience.</span>
        </div>
        <div>
          <span>Responsive design for use on both desktop and mobile devices.</span>
        </div>
        <div>
          <span>Downloadable QR codes in various formats (PNG, SVG, JPG).</span>
        </div>
        <div>
          <span>New QR code scanner feature for easy scanning and result display.</span>
        </div>
      </div>
      <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-gray-300 mt-10 mb-4">Advantages</h2>
      <p className="text-gray-700 dark:text-gray-300 text-lg italic leading-relaxed">
        Using our QR Code Generator WebApp offers numerous advantages:
      </p>
      <div className="space-y-3 mt-4 pl-4 dark:text-gray-300 text-md leading-snug text-gray-700">
        <div>
          <span><strong>Convenience:</strong> Generate QR codes on the fly without the need for any specialized software.</span>
        </div>
        <div>
          <span><strong>Versatility:</strong> Handle multiple QR code types with one application, saving time and effort.</span>
        </div>
        <div>
          <span><strong>Customization:</strong> Tailor the QR codes to your needs, whether it's adding specific data or adjusting the appearance.</span>
        </div>
        <div>
          <span><strong>Accessibility:</strong> Use the app on any device with internet access, ensuring you can create QR codes anytime, anywhere.</span>
        </div>
        <div>
          <span><strong>Efficiency:</strong> Streamline the process of sharing information, leading to improved communication and engagement.</span>
        </div>
      </div>
      <div>
        <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-gray-300 mt-10 mb-4">New QR Code Scanner Feature</h2>
        <div className="space-y-3 mt-4 pl-4 dark:text-gray-300 text-md leading-snug text-gray-700">
          <p>
            Our latest addition to the app is the QR Code Scanner feature. This allows users to scan any QR code and instantly view the content. The scanner supports various QR code types, ensuring that you can easily access the information encoded within any QR code you encounter.
          </p>
          <p>
            Simply open the scanner, point it at a QR code, and let our app do the rest. Whether it's a link, text, or contact information, our scanner will display the content in an easy-to-read format. This feature enhances the versatility of our app, making it not only a generator but also a comprehensive tool for QR code interaction.
          </p>
          <p>
            Experience the convenience and efficiency of our QR Code Generator WebApp today. Whether you're a business looking to enhance customer interaction or an individual wanting to simplify data sharing, our app is the perfect tool for you.
          </p>
        </div>

      </div>
    </div>
  );
};

export default About;
