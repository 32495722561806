import React from 'react';
import EmailFields from './fields/EmailFields';
import WhatsAppFields from './fields/WhatsAppFields';
import WifiFields from './fields/WifiFields';
import VCardFields from './fields/VCardFields';
import SMSFields from './fields/SMSFields';
import LocationFields from './fields/LocationFields';
import EventFields from './fields/EventFields';
import PayPalFields from './fields/PayPalFields';
import BitcoinFields from './fields/BitcoinFields';

const AdditionalFields = ({ type, additionalFields, setAdditionalFields }) => {
  switch (type) {
    case 'email':
      return <EmailFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'whatsapp':
      return <WhatsAppFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'wifi':
      return <WifiFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'vcard':
      return <VCardFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'sms':
      return <SMSFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'location':
      return <LocationFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'event':
      return <EventFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'paypal':
      return <PayPalFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    case 'bitcoin':
      return <BitcoinFields additionalFields={additionalFields} setAdditionalFields={setAdditionalFields} />;
    default:
      return null;
  }
};

export default AdditionalFields;
