const PayPalFields = ({ additionalFields, setAdditionalFields }) => (
    <>
      <div>
        <label htmlFor="paypalEmail" className="block text-gray-700 font-medium dark:text-gray-300">PayPal Email:</label>
        <input
          type="email"
          id="paypalEmail"
          name="paypalEmail"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.paypalEmail || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, paypalEmail: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="itemName" className="block text-gray-700 font-medium dark:text-gray-300">Item Name:</label>
        <input
          type="text"
          id="itemName"
          name="itemName"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.itemName || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, itemName: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="amount" className="block text-gray-700 font-medium dark:text-gray-300">Amount:</label>
        <input
          type="text"
          id="amount"
          name="amount"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.amount || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, amount: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="currencyCode" className="block text-gray-700 font-medium dark:text-gray-300">Currency Code:</label>
        <input
          type="text"
          id="currencyCode"
          name="currencyCode"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.currencyCode || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, currencyCode: e.target.value })}
          required
        />
      </div>
    </>
  );
  
  export default PayPalFields;
  