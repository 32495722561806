import React from 'react';
import TimezoneSelect from 'react-timezone-select';

const EventFields = ({ additionalFields, setAdditionalFields }) => (
  <>
    <div>
      <label htmlFor="eventName" className="block text-gray-700 font-medium dark:text-gray-300">Event Name:</label>
      <input
        type="text"
        id="eventName"
        name="eventName"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.eventName || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, eventName: e.target.value })}
        required
      />
    </div>
    <div>
      <label htmlFor="date" className="block text-gray-700 font-medium dark:text-gray-300">Date:</label>
      <input
        type="date"
        id="date"
        name="date"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.date || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, date: e.target.value })}
        required
      />
    </div>
    <div className="space-y-2">
      <label htmlFor="time" className="block text-gray-700 font-medium dark:text-gray-300">Time:</label>
      <input
        type="time"
        id="time"
        name="time"
        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 bg-white dark:bg-gray-700 dark:border-none dark:text-white"
        value={additionalFields.time || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, time: e.target.value })}
        required
      />
    </div>
    <div className="space-y-2">
      <label htmlFor="timeZone" className="block text-gray-700 font-medium dark:text-gray-300">Time Zone:</label>
      <TimezoneSelect
        value={additionalFields.timeZone || 'UTC'}
        onChange={(timezone) => setAdditionalFields({ ...additionalFields, timeZone: timezone.value })}
        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 dark:border-none dark:text-gray-600"
      />
    </div>
    <div>
      <label htmlFor="location" className="block text-gray-700 font-medium dark:text-gray-300">Location:</label>
      <input
        type="text"
        id="location"
        name="location"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.location || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, location: e.target.value })}
        required
      />
    </div>
    <div>
      <label htmlFor="endDate" className="block text-gray-700 font-medium dark:text-gray-300">End Date (optional):</label>
      <input
        type="date"
        id="endDate"
        name="endDate"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.endDate || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, endDate: e.target.value })}
      />
    </div>
    <div>
      <label htmlFor="endTime" className="block text-gray-700 font-medium dark:text-gray-300">End Time (optional):</label>
      <input
        type="time"
        id="endTime"
        name="endTime"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.endTime || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, endTime: e.target.value })}
      />
    </div>
    <div>
      <label htmlFor="description" className="block text-gray-700 font-medium dark:text-gray-300">Description (optional):</label>
      <textarea
        id="description"
        name="description"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.description || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, description: e.target.value })}
      />
    </div>
  </>
);

export default EventFields;
