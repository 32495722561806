const VCardFields = ({ additionalFields, setAdditionalFields }) => (
  <>
    <div>
      <label htmlFor="name" className="block text-gray-700 font-medium dark:text-gray-300">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.name || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, name: e.target.value })}
        required
      />
    </div>
    <div>
      <label htmlFor="phone" className="block text-gray-700 font-medium dark:text-gray-300">Phone Number:</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.phone || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, phone: e.target.value })}
        required
      />
    </div>
    <div>
      <label htmlFor="email" className="block text-gray-700 font-medium dark:text-gray-300">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.email || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, email: e.target.value })}
      />
    </div>
    <div>
      <label htmlFor="address" className="block text-gray-700 font-medium dark:text-gray-300">Address:</label>
      <input
        type="text"
        id="address"
        name="address"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.address || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, address: e.target.value })}
      />
    </div>
    <div>
      <label htmlFor="company" className="block text-gray-700 font-medium dark:text-gray-300">Company:</label>
      <input
        type="text"
        id="company"
        name="company"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"        
        value={additionalFields.company || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, company: e.target.value })}
      />
    </div>
    <div>
      <label htmlFor="title" className="block text-gray-700 font-medium dark:text-gray-300">Title:</label>
      <input
        type="text"
        id="title"
        name="title"
        className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
        value={additionalFields.title || ''}
        onChange={(e) => setAdditionalFields({ ...additionalFields, title: e.target.value })}
      />
    </div>
  </>
);

export default VCardFields;
