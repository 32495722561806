import React from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const PhoneFields = ({ data, setData }) => (
  <div id="data-field">
    <label htmlFor="phone" className="block text-gray-700 dark:text-gray-300 font-medium">Phone Number:</label>
    <PhoneInput
      country={'us'}
      value={data}
      onChange={(phone) => setData(phone)}
      containerClass="w-full mt-1"
      inputClass="w-full border-gray-300 dark:border-none dark:bg-gray-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 dark:text-gray-500"
      buttonClass="bg-white border-gray-300 dark:bg-gray-700 dark:border-none"
      dropdownClass="dark:bg-gray-700 dark:text-gray-500"
      countryCodeEditable={false}
      enableSearch={true}
    />
  </div>
);

export default PhoneFields;
