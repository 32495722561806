// src/components/Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsQrCodeScan } from "react-icons/bs";
import { FaSun, FaMoon } from 'react-icons/fa';
import { IoQrCodeOutline } from "react-icons/io5";
import { BsInfoSquare } from "react-icons/bs";
import ThemeContext from '../ThemeContext';

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-3">
          <Link to="/">
            <div className='flex flex-row items-center space-x-3'>
              <BsQrCodeScan size={42} className='text-gray-800 dark:text-gray-300' />
              <h1 className="text-lg md:text-3xl font-bold text-start text-gray-800 dark:text-gray-300 leading-tight">QR Code Generator</h1>
            </div>
          </Link>
          <nav className="flex items-center justify-center space-x-4">
            <Link
              to="/"
              className="text-gray-800 dark:text-gray-300 hover:text-blue-500 flex flex-col items-center"
            >
              <IoQrCodeOutline size={30} />
              <p className='text-xs mt-1'>Generate</p>
            </Link>
            <Link
              to="/about"
              className="text-gray-800 dark:text-gray-300 hover:text-blue-500 flex flex-col items-center"
            >
              <BsInfoSquare size={30} />
              <p className='text-xs mt-1'>About</p>
            </Link>
            <div className='flex flex-col items-center'>
              <button
                onClick={toggleTheme}
                className="px-2 py-1 bg-gray-50 border border-gray-800 dark:bg-gray-900 dark:shadow-white text-gray-800 rounded-md shadow-sm hover:bg-gray-200 focus:outline-none flex flex-col items-center"
              >
                {theme === 'light' ? <FaMoon size={22} /> : <FaSun size={22} className='text-white' />}
                <p className='text-xs mt-2 text-gray-800 dark:text-white'>
                  {theme === 'light' ? 'Dark' : 'Light'}
                </p>
              </button>
            </div>

          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
