const BitcoinFields = ({ additionalFields, setAdditionalFields }) => (
    <>
      <div>
        <label htmlFor="walletAddress" className="block text-gray-700 font-medium dark:text-gray-300">Wallet Address:</label>
        <input
          type="text"
          id="walletAddress"
          name="walletAddress"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.walletAddress || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, walletAddress: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="amount" className="block text-gray-700 font-medium dark:text-gray-300">Amount:</label>
        <input
          type="text"
          id="amount"
          name="amount"
          className="block w-full mt-1 border-gray-300 dark:text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 border dark:border-none dark:bg-gray-700"
          value={additionalFields.amount || ''}
          onChange={(e) => setAdditionalFields({ ...additionalFields, amount: e.target.value })}
          required
        />
      </div>
    </>
  );
  
  export default BitcoinFields;
  